// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'

// Components
import './components'

// Plugins
import './plugins'

// Sync router with store
import { sync } from 'vuex-router-sync'

// Application imports
import App from './App.vue'
import i18n from '@/i18n'
import router from '@/router'
import store from '@/store'
import Vuetify from 'vuetify'
import es from 'vuetify/es5/locale/es'
import theme from './plugins/theme'
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import axios from 'axios'
import VueParticles from 'vue-particles'
import "@/assets/scrollbar.css"
import Security from './security'
import Utils from './utils'
import browserDetect from "vue-browser-detect-plugin";
import Notifications from 'vue-notification'
import 'tippy.js/dist/tippy.css'
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
import "@/assets/date-time-picker.css"
import 'vue-swatches/dist/vue-swatches.css'
import VueApexCharts from 'vue-apexcharts'
import VueNumberInput from '@chenfengyuan/vue-number-input';
import VueLayers from 'vuelayers'
import 'vuelayers/lib/style.css'
import "vue-navigation-bar/dist/vue-navigation-bar.css";
import VueNavigationBar from "vue-navigation-bar";
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import VueCookies from 'vue-cookies'
import Help from './help';
import VTooltip from 'v-tooltip';
import '@/assets/tooltip.css';

Vue.use(VueCookies)
Vue.use(VueLayers)
Vue.use(VueNumberInput)
Vue.use(browserDetect)
Vue.use(VueParticles)
Vue.use(Vuetify)
Vue.use(Security)
Vue.use(Utils);
Vue.use(Help);
Vue.use(Notifications)
Vue.use(VueApexCharts)
Vue.use(VTooltip)

Vue.component('VueCtkDateTimePicker', VueCtkDateTimePicker);
Vue.component('apexchart', VueApexCharts);
Vue.component("vue-navigation-bar", VueNavigationBar);
Vue.component('v-select-advanced', vSelect)

//No mostrar Error de Vue
const ignoreWarnMessage = 'The .native modifier for v-on is only valid on components but it was used on <div>.';
Vue.config.warnHandler = function (msg, vm, trace) {
  // `trace` is the component hierarchy trace
  if (msg === ignoreWarnMessage) {
    msg = null;
    vm = null;
    trace = null;
  }
}


Vue.prototype.$http = axios

// Sets the default url used by all of this axios instance's requests
axios.defaults.baseURL = "/api";
axios.defaults.headers.get['Accept'] = 'application/json'

const token = localStorage.getItem('token')
if (token) {
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
}

// Sync store with router
sync(store, router)


axios.interceptors.response.use(function (response) {
  if(store.state.netError == true) store.state.netError = false;
  return response;
}, async function (error) {
  if (!error.response) {
    if(store.state.netError == false){
      store.state.netError = true;
    }
    
  } 
  const originalRequest = error.config;
  if (401 === error.response.status && !originalRequest._retry) {
    await store.dispatch('refreshtoken');
    originalRequest._retry = true;
    originalRequest.headers['Authorization'] = 'Bearer ' + store.state.token;
    originalRequest.baseURL = undefined;
    return Promise.resolve(await axios(originalRequest));
  }
  else if(403 === error.response.status) {
    store.dispatch('logout');
    router.go();
    return Promise.reject(error);
  } else {
      return Promise.reject(error);
  }
});

const vuetifyOpts = {
  theme: theme,
  lang: {
    locales: { es },
    current: 'es',
  }
}

Vue.config.productionTip = false

/* eslint-disable no-new */
new Vue({
  el: '#app',
  i18n,
  router,
  store,
  components: { App },
  render: h => h(App),
  template: '<App/>',
  vuetify: new Vuetify(vuetifyOpts)
})
//Variables
store.state.nombreEmpresa = "Gonher";
store.state.desconocidos = false;
store.state.desconocidosIds = [6,16];
store.state.clavePersonalizada = true;
store.state.calculadoraRollos = false;
store.state.maquila = false;
store.state.cargasMasivas = true;
store.state.inventario = true;
store.state.finalizarLabel = "Finalizar";
store.state.pinLength = 4;
store.state.pinType = "numbers";
store.state.reporteAuxiliar = false;
store.state.pantografo = false;
store.state.dashboard = null;
store.state.productos = null;
store.state.oee = null;
store.state.mantenimientos = null;
store.state.maquinas = null;
store.state.remanufactura = null;
store.state.ciclos = null;
store.state.paros = null;
store.state.trazabilidadInventario = false;
store.state.movimientosFactura = true;
store.state.iniciarAutoNuevaODT = true;
store.state.editarPzsNoOk = true;
store.state.editarTiempoMuerto = true;
store.state.tipoFiltroReportes = 1;
